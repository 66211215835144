import React from 'react';
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom';

import { changeToGoogleApisThumb, uri_pattern } from '../utility/Utility';
import { Product as ProductType } from '../types';
import './Product.scss';

interface IProps {
  product: ProductType;
}

const Product = (props: IProps) => {
  const { product } = props;
  let description = '';
  const url = changeToGoogleApisThumb(product.photoURIs[0], false);
  const url2x = changeToGoogleApisThumb(product.photoURIs[0], true);
  if (product.description) {
    description = product.description.replace(uri_pattern, '').trim();
  }
  return (
    <div className="product">
      <div className="my-4 no-gutters row">
        <Link to={`/${product.seller.username}/${product.uuid}`} className="mx-auto">
          <LazyLoad height={'calc(100vw / 3)'} offset={200} once>
            <img alt={product.description} src={url} srcSet={`${url2x} 2x`} />
          </LazyLoad>
        </Link>
      </div>
      <div className="description">{description}</div>
    </div>
  );
};

export default Product;
