import PouchDB from 'pouchdb-browser';
import PouchFind from 'pouchdb-find';
import { Product, User } from '../types';

PouchDB.plugin(PouchFind);

// import * as PouchDB from 'pouchdb';
const dbs = {
  products: new PouchDB<Product>('products'),
  users: new PouchDB<User>('users'),
};

export const getDB = async (collection: keyof typeof dbs) => {
  const db = dbs[collection];
  const info = await db.info();

  console.log(info);
  // var result = await db.getIndexes();
  // console.log(result);
  if (info.doc_count) {
    return db;
  }
  if (collection === 'products') {
    await db.createIndex({
      index: { fields: ['uuid'] },
    });
    await db.createIndex({
      index: { fields: ['tags'] },
    });
    await db.createIndex({
      index: { fields: ['seller.username'] },
    });
  } else if (collection === 'users') {
    await db.createIndex({
      index: { fields: ['_id'] },
    });
    await db.createIndex({
      index: { fields: ['username'] },
    });
  }
  const json = require(`../data/${collection}.json`);
  // @ts-ignore
  await db.bulkDocs<Product | User>(json);

  return db;
};
