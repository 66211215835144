import React from 'react';
import { useTranslation } from 'react-i18next';
import './NotFound.scss';

const NotFound = ({ location }: { location: any }) => {
  const { t } = useTranslation();
  return (
    <div className="not-found-container align-items-center d-flex flex-column justify-content-center not-found-container">
      <h3>
        {t('not-found.no-matches')}
        <code>{location.pathname}</code>
      </h3>
      <video autoPlay loop width="50%">
        <source src={require('../images/404.mp4')} type="video/mp4" />
      </video>
      <div className="shadow-video" />
    </div>
  );
};

export default React.memo(NotFound);
