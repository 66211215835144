import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import './Uploader.scss';

import Login from '../components/uploader/Login';

declare global {
  interface Window {
    APP_ID: string;
    Intercom: any;
  }
}

export type Item = {
  id: string;
  categoryIds?: number;
  description?: string;
  photos?: Array<any>;
  price?: string;
  ready: boolean;
  tags?: Array<any>;
  tagsText?: string;
  typeIds?: number;
  // uploaded: boolean,
};

type Props = WithTranslation;
class Uploader extends Component<Props> {
  render() {
    return <Login onLogin={() => {}} />;
  }
}

export default withTranslation()(Uploader);
