import React from 'react';
import { Link } from 'react-router-dom';

import './Testimonials.scss';

type Props = {};

function Testimonials() {
  return (
    <div className="testimonials row mx-md-auto no-gutters item-list text-center">
      <div className="col-12 col-sm-4">
        <Link to="/chereshnivska">
          <img className="avatar" alt="avatar" src={require('../images/chereshnivska.jpg')} />
          <h3 className="mt-3">Chereshnivska</h3>
        </Link>
        <blockquote>
          "Для мене ця платформа з огляду функціоналу і самої ідеї - це про турботу. все настільки продумано і зручно,
          умови роботи - я відчуваю що про мене подбали у багатьох аспектах" – Ірина
        </blockquote>
      </div>
      <div className="col-12 col-sm-4">
        <Link to="/kozar">
          <img className="avatar" alt="avatar" src={require('../images/kozar.jpg')} />
          <h3 className="mt-3">kozar</h3>
        </Link>
        <blockquote>
          "Ідеальний інструмент для продажів для молодих українських дизайнерів, альтернатива власному сайту, зручний
          інтерфейс та миттєва потужна технічна підтримка в будь якому питанні" – Мирослава
        </blockquote>
      </div>
      <div className="col-12 col-sm-4">
        <Link to="/ornament_bag_brand">
          <img className="avatar" alt="avatar" src={require('../images/ornament.jpg')} />
          <h3 className="mt-3">Ornament Bags</h3>
        </Link>
        <blockquote>
          "Це хороший спосіб для брендів та дизайнерів представити свою творчість та знайти потенційних покупців. Також
          ваш профіль може слугувати особистим сайтом, де представлений весь ваш асортимент, а для клієнтів можливість
          безпечно придбати продукцію" – Дарина
        </blockquote>
      </div>
    </div>
  );
}

export default React.memo(Testimonials);
