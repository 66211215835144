import React, { ReactNode } from 'react';
import { Link, NavLink, Route, NavLinkProps, withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import Logo from './Logo';
import './Header.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  showTagline: boolean;
  tagline: string;
};

function Header({ showTagline, tagline }: Props) {
  const { t } = useTranslation();
  return (
    <div className="header">
      <div className="row top-row justify-content-between mx-auto p-4">
        <div className="align-self-center">
          <MenuLink activeClassName="selected" className="btn btn-link" to="/how-it-works">
            {t('header.how-it-works')}
          </MenuLink>
        </div>
        <div className="align-self-center">
          <Link to="/uploader">
            <img alt="Uploader" width="30" height="30" src={require('../images/user.svg')} />
          </Link>
        </div>
      </div>
      <Logo />
      {showTagline && <h1>{t(tagline)}</h1>}
    </div>
  );
}

interface MenuLinkProps extends NavLinkProps {
  children: any;
  className: string;
  to: string;
}
interface GoBackProps extends RouteComponentProps {
  children: any;
  className: string;
}

function MenuLink(props: MenuLinkProps) {
  return (
    <Route
      path={props.to}
      // @ts-ignore
      children={({ match }) =>
        match ? (
          <GoBackComp className={props.className}>{props.children}</GoBackComp>
        ) : (
          <NavLink {...props}>{props.children}</NavLink>
        )
      }
    />
  );
}

const GoBack = ({ history, children, className }: GoBackProps) => (
  // eslint-disable-next-line react/jsx-no-bind
  <div role="button" className={className} onClick={() => history.goBack()}>
    {children}
  </div>
);

const GoBackComp = withRouter(GoBack);

Header.defaultProps = {
  showTagline: true,
  tagline: 'header.designers-shop',
};

export default React.memo(Header);
