import React from 'react';
import { Helmet } from 'react-helmet';
import { forceCheck } from 'react-lazyload';
import { RouteComponentProps } from 'react-router-dom';
import { Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';

import { Avatar, DropsList, ItemsList, StoreButtons, SocialIcons } from '../components';
import * as api from '../utility/api';
import { categories } from '../utility/ui';
import { uri_pattern } from '../utility/Utility';
import { Drop, Product, User as UserDoc } from '../types';
import './User.scss';

interface IRouteParams {
  uuid: string;
  userName: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {}

interface IState {
  drops: Drop[];
  items: Product[];
  user: UserDoc;
  activeTab: Tab;
}

enum Tab {
  About = -1,
  All = -2,
  Drops = -3,
}

export default class User extends React.Component<IProps, IState> {
  state = {
    activeTab: Tab.All,
    drops: [],
    items: [] as Product[],
    user: {} as UserDoc,
  };

  componentWillMount() {
    this.fetchData();
  }

  toggle = (tab: number) => {
    if (this.state.activeTab !== tab) {
      // hack to show tabs because react-lazyload is not activated for non-active tabs
      this.setState({ activeTab: tab }, () => forceCheck());
    }
  };

  async fetchData() {
    const { userName, uuid } = this.props.match.params;

    try {
      const res = await Promise.all([
        api.getUserByUsername(userName),
        api.getProductsByUsername(userName),
        // api.get(`api/v2/drops/?username=${userName}`),
      ]);

      // if there are no items show the About tag
      if (res[1].length < 1) this.toggle(Tab.About);

      // TODO: if there's only one category of items (accessories, etc.) set to that tab

      //#region development
      /*
      const drops = [
        {
          posted: false,
          products: [
            {
              photoURIs: [
                'https://assets.onova.co/products/8LOvCz1MR-1-1546028852413.jpg',
              ],
              _id: '5c2687346657400c3ff4567b',
            },
            {
              photoURIs: [
                'https://assets.onova.co/products/8LOvCz1MR-1-1546028852413.jpg',
              ],
              _id: '5c2687346657400c3ff4567c',
            },
            {
              photoURIs: [
                'https://assets.onova.co/products/8LOvCz1MR-1-1546028852413.jpg',
              ],
              _id: '5c2687346657400c3ff4567d',
            },
          ],
          status: 'valid',
          _id: '5c2687346657400c3ff4567a',
          scheduledAt: '2019-01-17T20:48:56.891Z',
          seller: {
            shippingAddress: {
              firstName: 'Олександр',
              lastName: 'Костінський ',
              city: 'db5c88f5-391c-11dd-90d9-001a92567626',
              departmentNovaposhta: '39931b85-e1c2-11e3-8c4a-0050568002cf',
            },
            accountStatus: 'verified',
            _id: '5afaa93daeeb1453812fc011',
            username: 'alex',
            profilePic:
              'http://assets.onova.co/users/5afaa93daeeb1453812fc011-1526385408286.jpg',
            displayName: 'Alex',
          },
          createdAt: '2018-12-28T20:27:32.932Z',
          updatedAt: '2018-12-28T20:27:32.932Z',
          uuid: 'yAyE262fS',
        },
      ];
      */
      //#endregion

      this.setState({
        user: res[0],
        items: res[1],
        drops: [],
        // drops: drops,
      });

      // link to a specific Drop
      if (uuid) this.toggle(Tab.Drops);
    } catch (error) {
      // redirect to homepage
      this.props.history.push('/');
      console.error(error);
    }
  }

  render() {
    const { drops, user, items, activeTab } = this.state;

    if (!drops || !Object.keys(user).length || !items) return null;

    let bio;
    if (user.bio) bio = user.bio.replace(uri_pattern, '').trim();

    return (
      <React.Fragment>
        <Helmet title={(user.displayName || `@${user.username}`) + ' shop'} />
        <div className="user-page">
          <Nav tabs className="d-inline-flex mb-5">
            <NavItem className="px-sm-3">
              {/* eslint-disable react/jsx-no-bind */}
              <NavLink className={activeTab === Tab.About ? 'active' : ''} onClick={() => this.toggle(Tab.About)}>
                Бренд
              </NavLink>
            </NavItem>
            {/* clothes */}
            {items.filter(i => [0, 1, 2, 3].includes(i.categoryIds[0])).length > 0 && (
              <NavItem key={3} className="px-sm-3">
                {/* @ts-ignore */}
                <NavLink className={activeTab === 3 ? 'active' : ''} onClick={() => this.toggle(3)}>
                  Одяг
                </NavLink>
              </NavItem>
            )}
            {categories
              .filter((c, i) => i > 0)
              .map(
                cat =>
                  items.filter(i => i.categoryIds[0] === cat.value).length > 0 && (
                    <NavItem key={cat.value} className="px-sm-3">
                      <NavLink
                        className={activeTab === cat.value ? 'active' : ''}
                        onClick={() => this.toggle(cat.value)}>
                        {cat.label}
                      </NavLink>
                    </NavItem>
                  )
              )}
            {drops.length > 0 && (
              <NavItem className="px-sm-3">
                <NavLink className={activeTab === Tab.Drops ? 'active' : ''} onClick={() => this.toggle(Tab.Drops)}>
                  Анонси
                </NavLink>
              </NavItem>
            )}
            <NavItem
              style={{
                left: 0,
                position: 'absolute',
                right: 0,
                top: 52,
              }}
              className="pt-1 px-0 px-sm-3">
              <NavLink onClick={() => this.toggle(Tab.All)}>
                {/* eslint-enable react/jsx-no-bind */}
                <h1 className="text-truncate" style={{ textTransform: 'initial' }}>
                  {user.displayName || user.username}
                </h1>
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={Tab.About}>
              <div className="px-5 user-data">
                <Row noGutters>
                  <div className="col">
                    <p className="bio lead text-left mb-0">{bio}</p>
                    <SocialIcons user={user} />
                    <Row noGutters className="my-4">
                      <Avatar user={user} size={96} />
                      <div className="username my-auto ml-3">
                        <p className="lead mb-0">{`@${user.username}`}</p>
                      </div>
                    </Row>
                    <StoreButtons classNames="text-left no-gutters" />
                  </div>
                </Row>
                {/* <Row noGutters>
                  <div className="col">
                    <StoreButtons
                      vertical
                      classNames="d-inline-flex"
                    />
                  </div>
                </Row> */}
              </div>
            </TabPane>
            {items.filter(i => [0, 1, 2, 3].includes(i.categoryIds[0])) && (
              <TabPane key={3} tabId={3}>
                <ItemsList items={items.filter(i => [0, 1, 2, 3].includes(i.categoryIds[0]))} />
              </TabPane>
            )}
            {categories
              .filter((c, i) => i > 0)
              .map(cat => {
                const catItems = items.filter(i => i.categoryIds[0] === cat.value);

                if (!catItems) return null;
                return (
                  <TabPane key={cat.value} tabId={cat.value}>
                    <ItemsList items={catItems} />
                  </TabPane>
                );
              })}
            <TabPane tabId={Tab.All}>
              <ItemsList items={items} />
            </TabPane>
            <TabPane tabId={Tab.Drops}>{drops.length > 0 && <DropsList drops={drops} />}</TabPane>
          </TabContent>

          <footer className="py-3" />
        </div>
      </React.Fragment>
    );
  }
}
