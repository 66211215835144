export const category_radio_grp_1 = [
  // { label: 'Чоловіче', value: 0 },
  // { label: 'Жіноче', value: 1 },
  // { label: 'Взуття', value: 2 },
  { label: 'Одяг', value: 3 },
];

export const category_radio_grp_2 = [
  { label: 'Прикраси', value: 10 },
  { label: 'Сумки', value: 11 },
  // { label: 'Аксесуари', value: 12 },
];

export const category_radio_grp_3 = [
  // { label: 'Меблі', value: 20 },
  // { label: 'Мистецтво', value: 21 },
  { label: 'Побут', value: 22 },
];

export const categories = [...category_radio_grp_1, ...category_radio_grp_2, ...category_radio_grp_3];
