import React from 'react';
import UserAvatar from 'react-user-avatar';

import { changeToHTTPS } from '../utility/Utility';

import { User } from '../types';
import './Avatar.scss';

interface IProps {
  user: User;
  size?: number;
}

const Avatar = ({ user, size }: IProps) => {
  if (user.profilePic) {
    const url = changeToHTTPS(user.profilePic);
    return <img className="avatar" alt="avatar" src={url} />;
  }

  return <UserAvatar size={size} className="user-avatar-inner" name={user.username} />;
};

export default React.memo(Avatar);
