import React from 'react';
import { useTranslation } from 'react-i18next';

import { Header, StoreButtons } from '../components';
import './HowItWorks.scss';

function HowItWorks() {
  const { t } = useTranslation();
  return (
    <div className="container howitworks-page">
      <Header tagline={t('how-it-works.download-mobile-app')} />
      <StoreButtons classNames="mx-auto text-center" />
      <div className="header">
        <h1 className="mb-5 pt-4">{t('how-it-works.create-shop')}</h1>
        <div className="embed-responsive embed-responsive-16by9">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/AMt3pKkZ0Gc"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            // allowFullscreen
            title="video"
          />
        </div>
        <h1 className="my-5 pt-4">{t('how-it-works.buy-and-sell')}</h1>
        <h4 className="pb-5 mb-5">{t('how-it-works.fee')}</h4>
      </div>
    </div>
  );
}

export default HowItWorks;
