import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  width?: number;
  height?: number;
};

const Logo = ({ width = 75, height = 75 }: Props) => (
  <Link to="/">
    <img
      style={{ width: height, height: width }}
      alt="Onova"
      className="my-5"
      src={require('../images/icon-150px.png')}
    />
  </Link>
);

export default Logo;
