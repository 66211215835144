import React from 'react';
import { Link } from 'react-router-dom';

// import { uri_pattern_all } from '../utility/Utility';
import { Avatar } from './';
import './Brand.scss';
import { User } from '../types';

interface IProps {
  user: User;
}

const Brand = (props: IProps) => {
  const { user } = props;

  // let bio;
  // if (user.bio) bio = user.bio.replace(uri_pattern_all, '').trim();

  return (
    <div className="brand">
      <Link to={`/${user.username}/`} className="my-4 no-gutters row d-flex justify-content-center">
        <Avatar user={user} />
      </Link>
      <div className="text-center">{user.displayName}</div>
    </div>
  );
};

export default Brand;
