import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { forceCheck } from 'react-lazyload';
import { toaster } from 'evergreen-ui';

import './Landing.scss';
import Brand from '../components/Brand';
import Product from '../components/Product';
import { RouteComponentProps } from 'react-router';
import * as api from '../utility/api';
import { User as UserDoc, Product as Products } from '../types';

interface IRouteParams {
  category: string;
}

interface IProps extends RouteComponentProps<IRouteParams>, WithTranslation {}

interface IState {
  brands: UserDoc[];
  products: Products[];
  activeTab: Tab;
  foundProducts: boolean;
}

interface ICategories {
  // 'clothes-men': number;
  // 'clothes-women': number;
  // 'clothes-shoes': number;
  clothes: number;
  jewelry: number;
  bags: number;
  // 'accessories': number;
  // 'furniture': number;
  // 'art': number;
  interior: number;

  [key: string]: number;
}

enum Tab {
  brands = -1,
  products = -2,
}

const categories: ICategories = {
  // 'clothes-men': 0,
  // 'clothes-women': 1,
  // 'clothes-shoes': 2,
  clothes: 3,
  jewelry: 10,
  bags: 11,
  // 'accessories': 12,
  // 'furniture': 20,
  // 'art': 21,
  interior: 22,
};

class Landing extends Component<IProps, IState> {
  state = {
    brands: [] as UserDoc[],
    products: [] as Products[],
    activeTab: Tab.brands,
    foundProducts: true,
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevState.activeTab !== this.state.activeTab) {
      this.getData();
    }
  }

  getData() {
    if (this.state.activeTab === Tab.products) {
      this.fetchProducts();
    } else {
      this.fetchBrands();
    }
  }

  toggle = (tab: Tab) => {
    if (this.state.activeTab !== tab) {
      // hack to show tabs because react-lazyload is not activated for non-active tabs
      this.setState({ activeTab: tab }, () => forceCheck());
      // this.getData();
    }
  };

  async fetchBrands() {
    const category = this.getCategory();
    try {
      const brands = await api.getUsersByCategory(category);

      this.setState({ brands });
    } catch (e) {
      const error = e as any;
      toaster.danger(error.message, { duration: 30 });
      console.error(JSON.stringify(error));
    }
  }

  async fetchProducts() {
    const category = this.getCategory();

    if (!category && category !== 0) {
      this.props.history.push('/');
    }

    try {
      let products = await api.getProducts();
      // let products = await api.getProducts(`?limit=90&categoryIds=${category}`);

      if (!products || !products.length) {
        products = await api.getProducts();
        this.setState({ foundProducts: false });
      }

      products = products.filter((product: any) => product.price.$numberDecimal !== '99999.00');

      this.setState({ products });
    } catch (e) {
      const error = e as any;
      toaster.danger(error.message, { duration: 30 });
      console.error(JSON.stringify(error));
    }
  }

  getCategory() {
    return categories[this.props.match.params.category];
  }

  renderBrands() {
    return this.state.brands.map(brand => (
      <div className="col-4" key={brand.username}>
        <Brand user={brand} />
      </div>
    ));
  }

  renderProducts() {
    return this.state.products.map(product => (
      <div className="col-4" key={product.uuid}>
        <Product product={product} />
      </div>
    ));
  }

  render() {
    const { activeTab, foundProducts } = this.state;
    const { match, t } = this.props;
    const { category } = match.params || '';
    return (
      <React.Fragment>
        <div className="landing-page">
          <Helmet title={t(`categories.${category}`)} />
          <Nav tabs className="d-inline-flex mb-5">
            <NavItem className="px-sm-3">
              {/* eslint-disable react/jsx-no-bind */}
              <NavLink className={activeTab === Tab.brands ? 'active' : ''} onClick={() => this.toggle(Tab.brands)}>
                {t('landing.brands')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={activeTab === Tab.products ? 'active' : ''} onClick={() => this.toggle(Tab.products)}>
                {t('landing.products')}
              </NavLink>
              {/* eslint-enable react/jsx-no-bind */}
            </NavItem>
            <NavItem
              style={{
                border: 0,
                height: '4em',
                left: 0,
                position: 'absolute',
                right: 0,
                top: 40,
              }}
              className="pt-2 pt-sm-4">
              <h1 className="text-truncate" style={{ textTransform: 'initial' }}>
                {t(`categories.${category}`)}
              </h1>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={Tab.brands}>
              <div className="row mx-md-auto no-gutters item-list">{this.renderBrands()}</div>
            </TabPane>
            <TabPane tabId={Tab.products}>
              {!foundProducts && (
                <div>
                  {/* eslint-disable-next-line no-template-curly-in-string */}
                  <div>{t('landing.products-not-found').replace('${category}', t(`categories.${category}`))}</div>
                  <div className="all-products">{t('landing.other-products')}</div>
                </div>
              )}
              <div className="row mx-md-auto no-gutters item-list">{this.renderProducts()}</div>
            </TabPane>
          </TabContent>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(Landing);
